<template>
    <Observer
        v-if="displayContainer"
        :options="CONTAINER_OBSERVER_OPTIONS"
        :observe-once="true"
        :class="{ 'has-sizes-filter': hasSizesFilter }"
        class="recommended-products-simple-slider-wrapper-new"
        @intersect="displayContent($event)"
    >
        <ContainerContent
            v-if="isDisplayed"
            :align-left="true"
            class="recommended-slider-content"
        >
            <Loader v-if="!isLoaded" height="100%" />
            <RecommendedProductsDataProvider
                :recommendation-type="recommendationType"
                :category-breadcrumbs="categoryBreadcrumbs"
                :product-sku="productSku"
                :custom-campaign-id="customCampaignId"
                :size="selectedSize"
                @product-click="onProductClick($event)"
                @loaded="onLoadedProducts($event)"
                @error="handleError()"
            >
                <component
                    :is="productsSliderComponent"
                    v-if="isLoaded"
                    :products="productsLoaded"
                    :has-wish-list-button="!hasModivoProducts"
                    :slider-title="heading"
                    :slider-subtitle="subheading"
                    :link="link"
                    :action-field="recommendationType"
                    :with-impression-click="false"
                    :has-modivo-products="hasModivoProducts"
                    @navigation-button-click="
                        emitNavigationButtonClickEvent($event)
                    "
                    @on-touch-end="
                        emitRecommendationEvent(RECOMMENDATIONS_SCROLL)
                    "
                    @see-more-click="
                        emitRecommendationEvent(RECOMMENDATIONS_SEE_MORE)
                    "
                    @product-click="onProductClick($event)"
                    @wishlist-button-click="onAddToWishlistClick($event)"
                >
                    <template #header>
                        <RecommendationSizesFilter
                            v-if="hasSizesFilter"
                            :sizes="sizes"
                            :selected-size="selectedSize"
                            :is-disabled="isSizeFilterLoading"
                            class="sizes-filter"
                            @size-select="onSizeSelect($event)"
                        />
                    </template>
                </component>
            </RecommendedProductsDataProvider>
        </ContainerContent>
    </Observer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
    DEFAULT_DEBOUNCE_TIME,
    HEADING_SLOT_INDEX,
    SUBHEADING_SLOT_INDEX,
    LINK_SLOT_INDEX,
    MODIVO_PRODUCTS_SLOT_INDEX,
    MODIVO_SLOT_NAME,
    DEFAULT_SLOT_CONTENT,
    EMPTY_SLOT_CONTENT,
} from '@configs/recommendations';
import { RECOMMENDATION_SIZE_FILTER_KEY } from '@configs/storage';

import { SYNERISE_RECOMMENDATION_PLACEMENTS } from '@types/Synerise';

import SyneriseDataLayerProduct from '@models/Analytics/SyneriseDataLayerProduct';

import {
    RECOMMENDATIONS_SEE_MORE,
    RECOMMENDATIONS_SCROLL,
    RECOMMENDATIONS_BAD_RESPONSE,
    RECOMMENDATIONS_VIEW,
    RECOMMENDATIONS_PRODUCT_CLICKED,
    RECOMMENDATIONS_ADD_TO_WISHLIST,
} from '@analytics-types/Events';
import {
    PRODUCT_RECOMMENDATION_SLIDER_ARROW_CLICK,
    PRODUCT_RECOMMENDATION_FILTER_CLICK,
} from '@analytics-module/modules/product/types/Events';
import { LABELS as GLOBAL_LABELS } from '@analytics-types/Labels';
import { ACTIONS as PRODUCT_ACTIONS } from '@analytics-module/modules/product/types/Actions';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-module/modules/product/meta';

import { interactionResponse } from '@assets/performance';
import { debounceAggregate } from '@assets/debounce-aggregate';
import { checkIfExistsInValuesMap } from '@assets/props';

import LoadProducts from '@mixins/LoadProducts';

import Loader from '@atoms/Loader/Loader';
import Observer from '@atoms/Observer/Observer';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

const FILTER_SIZES = {
    man: [39, 40, 41, 42, 43, 44, 45, 46, 47],
    woman: [35, 36, 37, 38, 39, 40, 41, 42],
};

export default {
    name: 'RecommendedProductsSliderWrapperNew',

    components: {
        Observer,
        Loader,
        ContainerContent,

        RecommendedProductsDataProvider: () => ({
            component: import(
                /* webpackChunkName: "recommended-products-data-provider" */
                '@molecules/RecommendedProductsDataProvider/RecommendedProductsDataProvider'
            ),
        }),

        RecommendationSizesFilter: () => ({
            component: import(
                /* webpackChunkName: "recommendation-sizes-filter" */
                '@organisms/RecommendedProductsSliderWrapperNew/RecommendationSizesFilter'
            ),
        }),
    },

    mixins: [LoadProducts],

    props: {
        recommendationType: {
            type: String,
            required: true,
            validator: checkIfExistsInValuesMap(
                SYNERISE_RECOMMENDATION_PLACEMENTS
            ),
        },

        placementPageName: {
            type: String,
            required: true,
        },

        categoryBreadcrumbs: {
            type: Array,
            default: () => [],
        },

        productSku: {
            type: [String, Array],
            default: '',
        },

        customCampaignId: {
            type: String,
            default: '',
        },

        isPageEventEmittedPromise: {
            type: Promise,
            default() {
                return Promise.resolve();
            },
        },

        isSizeFilterEnabled: {
            type: Boolean,
            default: false,
        },

        categoryGender: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            isDisplayed: false,
            displayContainer: true,
            sliderExtras: {},
            localSelectedSize: null,
            isSizeFilterLoading: false,
            sizeFilters: {},
            sendSavedFilterEvent: true,
        };
    },

    computed: {
        ...mapConfigGetters(['locale', 'currency']),

        isLoaded() {
            return this.productsLoaded.length;
        },

        heading() {
            return this.getSliderExtrasData(HEADING_SLOT_INDEX);
        },

        subheading() {
            return this.hasSizesFilter
                ? `${this.$t('Choose a size')}:`
                : this.getSliderExtrasData(SUBHEADING_SLOT_INDEX);
        },

        link() {
            return this.getSliderExtrasData(LINK_SLOT_INDEX);
        },

        hasModivoProducts() {
            return (
                this.getSliderExtrasData(MODIVO_PRODUCTS_SLOT_INDEX) ===
                MODIVO_SLOT_NAME
            );
        },

        eventData() {
            return {
                currentScreenName: this.placementPageName,
                campaignID: this.customCampaignId || this.campaignId,
                campaignHash: this.sliderExtras?.correlationId || '',
            };
        },

        campaignId() {
            return this.$services.recommendations.getCampaignIdByType(
                this.recommendationType,
                this.locale
            );
        },

        sizes() {
            return (FILTER_SIZES[this.categoryGender] || []).map(size =>
                String(size)
            );
        },

        hasSizesFilter() {
            return this.sizes.length > 0 && this.isSizeFilterEnabled;
        },

        selectedSize() {
            if (!this.isSizeFilterEnabled) {
                return null;
            }

            return this.localSelectedSize;
        },
    },

    watch: {
        localSelectedSize() {
            this.isSizeFilterLoading = true;

            this.saveSelectedSize();
        },
    },

    beforeCreate() {
        this.CONTAINER_OBSERVER_OPTIONS = {
            root: null,
            threshold: 0,
            rootMargin: '0px 0px 200px 0px',
        };

        this.RECOMMENDATIONS_SEE_MORE = RECOMMENDATIONS_SEE_MORE;
        this.RECOMMENDATIONS_SCROLL = RECOMMENDATIONS_SCROLL;

        this.debouncedOnRecommendationView = debounceAggregate(products => {
            this.sendRecommendationViewsToAnalytics(products);
        }, DEFAULT_DEBOUNCE_TIME);
    },

    mounted() {
        this.sizeFilters =
            this.$storage.getItem(RECOMMENDATION_SIZE_FILTER_KEY) || {};

        this.localSelectedSize = this.sizeFilters[this.categoryGender] || null;
    },

    methods: {
        async onLoadedProducts({ products, extras }) {
            await this.loadProductsSliderComponent();
            this.sliderExtras = extras;

            this.productsLoaded = products;
            this.isSizeFilterLoading = false;

            if (!products.length) {
                this.displayContainer = false;
                this.emitBadResponseEvent();
            }
        },

        onProductClick({ product, index }) {
            this.emitRecommendationEventWithProduct(
                product,
                index,
                RECOMMENDATIONS_PRODUCT_CLICKED
            );
        },

        async emitBadResponseEvent() {
            await this.isPageEventEmittedPromise;

            this.emitRecommendationEvent(RECOMMENDATIONS_BAD_RESPONSE);
        },

        handleError() {
            this.displayContainer = false;
            this.isDisplayed = false;
            this.isSizeFilterLoading = false;

            this.emitBadResponseEvent();
        },

        displayContent(intersect) {
            if (intersect) {
                this.isDisplayed = true;
            }
        },

        emitNavigationButtonClickEvent(direction) {
            if (this.recommendationType) {
                this.$analytics.moduleEmit(
                    PRODUCT_MODULE_NAME,
                    PRODUCT_RECOMMENDATION_SLIDER_ARROW_CLICK,
                    {
                        sliderName: this.recommendationType,
                        direction:
                            direction === 1
                                ? GLOBAL_LABELS.NAV_RIGHT
                                : GLOBAL_LABELS.NAV_LEFT,
                    }
                );
            }

            this.emitRecommendationEvent(RECOMMENDATIONS_SCROLL);
        },

        async emitFilterRecommendationClickEvent(action, size) {
            await interactionResponse();

            this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_RECOMMENDATION_FILTER_CLICK,
                {
                    action,
                    size,
                    placement: this.recommendationType,
                }
            );
        },

        getSliderExtrasData(slotIndex) {
            const name = this.sliderExtras?.slots?.[slotIndex]?.name || '';

            if ([DEFAULT_SLOT_CONTENT, EMPTY_SLOT_CONTENT].includes(name)) {
                return '';
            }

            return name === '-' ? '' : name;
        },

        sendRecommendationViewsToAnalytics(products) {
            const productsToSend = products
                .flat()
                .map(({ product, index }) =>
                    this.buildSyneriseDataLayerProduct(product, index)
                );

            this.$analytics.emit(RECOMMENDATIONS_VIEW, {
                ...this.eventData,
                currency: this.currency,
                recommendationProducts: productsToSend,
            });

            if (
                this.hasSizesFilter &&
                this.localSelectedSize &&
                this.sendSavedFilterEvent
            ) {
                this.emitFilterRecommendationClickEvent(
                    PRODUCT_ACTIONS.VIEW_SAVED,
                    this.selectedSize
                );

                this.sendSavedFilterEvent = false;
            }
        },

        onAddToWishlistClick({ product, index, isAdded }) {
            if (isAdded) {
                return;
            }

            this.emitRecommendationEventWithProduct(
                product,
                index,
                RECOMMENDATIONS_ADD_TO_WISHLIST
            );
        },

        emitRecommendationEvent(eventName) {
            this.$analytics.emit(eventName, {
                ...this.eventData,
                itemListName: this.recommendationType,
            });
        },

        buildSyneriseDataLayerProduct(product, index) {
            return new SyneriseDataLayerProduct({
                product,
                position: index,
                recommendationType: this.recommendationType,
                pageType: this.$route.name,
            }).build();
        },

        emitRecommendationEventWithProduct(product, index, eventName) {
            this.$analytics.emit(eventName, {
                currency: this.currency,
                value: product.price.promotional.amount,
                actionFieldList: this.recommendationType,
                recommendationProducts: [
                    this.buildSyneriseDataLayerProduct(product, index),
                ],
                ...this.eventData,
            });
        },

        onSizeSelect(size) {
            if (this.isSizeFilterLoading) {
                return;
            }

            if (this.localSelectedSize === size) {
                this.localSelectedSize = null;

                this.emitFilterRecommendationClickEvent(
                    PRODUCT_ACTIONS.UNSET,
                    size
                );

                return;
            }

            const filterAction = this.localSelectedSize
                ? PRODUCT_ACTIONS.CHANGE
                : PRODUCT_ACTIONS.SET;

            this.emitFilterRecommendationClickEvent(filterAction, size);

            this.localSelectedSize = size;
        },

        saveSelectedSize() {
            this.sizeFilters = {
                ...this.sizeFilters,
                [this.categoryGender]: this.localSelectedSize,
            };

            this.$storage.setItem(
                RECOMMENDATION_SIZE_FILTER_KEY,
                this.sizeFilters
            );
        },
    },
};
</script>

<style lang="scss" scoped>
$title-height: $tailwindcss-spacing-8;
$title-height-lg: 68px;
$subtitle-height: calc(#{$tailwindcss-spacing-4} + #{$tailwindcss-spacing-3});
$slide-height: 276px;
$slide-height-lg: 384px;

@mixin products-slider-height($title-height, $slide-height) {
    min-height: calc(#{$title-height} + #{$slide-height} + #{$subtitle-height});
}

.recommended-products-simple-slider-wrapper-new {
    @apply w-full flex;

    @include products-slider-height($title-height, $slide-height);

    .recommended-slider-content {
        @apply px-0;
    }

    .sizes-filter {
        @apply mt-ui-3;
    }

    @screen lg {
        @include products-slider-height($title-height-lg, $slide-height-lg);

        &.has-sizes-filter {
            &:deep(.heading-wrapper) {
                @apply mb-ui-4;
            }
        }

        .recommended-slider-content {
            @apply px-5;
        }

        &:deep(.products-slider .slider-title) {
            @apply text-xl leading-xl;
        }

        .sizes-filter {
            @apply mt-ui-4;
        }
    }
}
</style>
